import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { defaultDataServiceConfig } from './config';
import { entityConfig } from './entity-metadata';
import { StoreService } from './store.service';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const indexeddbConfig: DBConfig  = {
  name: '5emo',
  version: 1,
  objectStoresMeta: [
  {
    store: 'emotions',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
    ]
  },
  {
    store: 'emoobject',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
    ]
  }
  ]
};

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    NgxIndexedDBModule.forRoot(indexeddbConfig)
  ],
  providers: [
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }
  ]
})

export class AppStoreModule {

  static forRoot(): ModuleWithProviders<AppStoreModule> {

      return {
          ngModule: AppStoreModule,
          providers: [StoreService]
      }
  };
}
