import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatListModule} from '@angular/material/list';
import { AboutComponent } from './about.component';
import { ToolbarModule } from '../../toolbar/toolbar.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { ImprintComponent } from '../imprint/imprint.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { TermsComponent } from '../terms/terms.component';


@NgModule({
  declarations: [
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    ImprintComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    CommonModule,
    SharedModule,
    MatListModule,
    ToolbarModule
  ]
})
export class AboutModule { }
