<app-toolbar>
    <h2>{{'SIGNIN_INTRO' | translate}}</h2>
    <p style="margin: 20px;">{{'SIGNIN_EXP' | translate}}</p>

    <mat-list role="list">
        <mat-list-item *ngIf="!loggedIn" role="listitem" [routerLink]="['/profile']" [queryParamsHandling]="'preserve'" [preserveFragment]=true >
            <button class="button-center" mat-stroked-button>{{'SIGNIN' | translate}}</button>
        </mat-list-item>
        <mat-list-item *ngIf="loggedIn" role="listitem" [routerLink]="['/profile']" [queryParamsHandling]="'preserve'" [preserveFragment]=true >
            <button class="button-center" mat-stroked-button>{{'PROFILE' | translate}}</button>
        </mat-list-item>
        <mat-list-item *ngIf="loggedIn" role="listitem" >
            <button class="button-center" (click)="logout()" mat-stroked-button>{{'SIGNOUT' | translate}}</button>
        </mat-list-item>
    </mat-list>
</app-toolbar>
