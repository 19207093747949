import { Position } from "./emotion";

export class User {

  uniqueId: string | undefined;
  auth_time: number | undefined;
  email: string | undefined;
  exp: number | undefined;
  family_name: string | undefined;
  gender: string | undefined;
  given_name: string | undefined;
  id: string | undefined;
  idp: string | undefined;
  idp_access_token: string | undefined;
  name: string | undefined;
  order_active: string | undefined;
  order_direction: string | undefined;
  page_index: number | undefined;
  page_size: number | undefined;
  position: Position | undefined;
  userid: string | undefined;
  username: string | undefined;
  yearofbirth: number | undefined;


  constructor(other?: Partial<User>) {

    if (other) {

      this.uniqueId = other.uniqueId;
      this.auth_time = other.auth_time;
      this.email = other.email;
      this.exp = other.exp;
      this.family_name = other.family_name;
      this.gender = other.gender;
      this.given_name = other.given_name;
      this.id = other.id;
      this.idp = other.idp;
      this.idp_access_token = other.idp_access_token;
      this.name = other.name;
      this.order_active = other.order_active;
      this.order_direction = other.order_direction;
      this.page_index = other.page_index;
      this.page_size = other.page_size;
      this.position = other.position;
      this.userid = other.userid;
      this.username = other.username;
      this.yearofbirth = other.yearofbirth;
    }
  }
}
