import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { bounceInOnEnterAnimation, bounceOutOnLeaveAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { fromEvent, interval, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import packageInfo from '../../../package.json';
import { EmoObject, Emotion } from '../core/model';
import { SharedService } from '../shared/shared.service';
import { StoreService } from '../store/store.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EmotionsBottomSheet } from './emotions.bottomsheet';

/**
 * @title Emotions
 */
@Component({
  selector: 'app-emotions',
  templateUrl: './emotions.component.html',
  styleUrls: ['./emotions.component.scss'],
  animations: [
    bounceInOnEnterAnimation({ anchor: 'enter-bounce', duration: 700, delay: 10 }),
    bounceOutOnLeaveAnimation({ anchor: 'leave-bounce', duration: 700, delay: 10 }),
    fadeInOnEnterAnimation({ anchor: 'enter-fade', duration: 700, delay: 10 }),
    fadeOutOnLeaveAnimation({ anchor: 'leave-fade', duration: 700, delay: 10 })
  ],
  encapsulation: ViewEncapsulation.None
})
export class EmotionsComponent implements OnInit {

  private mode?: string;
  private emotion: Emotion = new Emotion();
  public client_ver: string = packageInfo.version;
  isIframe = false;
  public emoobject: EmoObject = new EmoObject();

  public qrcode: string = environment.SHORTURL;
  transparams = {ownername: '5 Emotions'};
  private _bottomsheetref: EmotionsBottomSheet | undefined;

  showEmojis = false;
  showTitle = false;
  showThanks = false;
  showHelp = false;
  showQR = false;
  useGeo = false;

  // UI variables
  progress_value = 0;
  progress_sec: number = 0;
  private windowsize = {width: 0, height: 0};

  private poweredby_progress_timer? : Observable<number>;
  private poweredby_timer_subscriber? : Subscription;

  emotitle = "";
  emoji1 = "/assets/icons/emoji-classic-fill-1.png";
  emoji2 = "/assets/icons/emoji-classic-fill-2.png";
  emoji3 = "/assets/icons/emoji-classic-fill-3.png";
  emoji4 = "/assets/icons/emoji-classic-fill-4.png";
  emoji5 = "/assets/icons/emoji-classic-fill-5.png";

  thanksmessage: string | undefined;
  smallthanks = false;
  canpopup = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router, 
    private route: ActivatedRoute,
    private shared: SharedService,
    private store: StoreService,
    public translate: TranslateService,
    private titleService: Title,
    private _bottomSheet: MatBottomSheet
    ) {

      this.route.queryParamMap.subscribe(paramMap => {

        paramMap?.keys.forEach((key) => { 

          let v = paramMap.get(key) as string;
          if (this.emotion.params === undefined) {
            this.emotion.params = {};
          }
    
          this.emotion.params[key] = v;
          if (key === 'm') {
            this.mode = v;
          }
        })
      });
  
      this.emoobject.objectid = "";
      this.emoobject.customid = "";
      this.emoobject.title = "";
      this.emoobject.emojiStyle = "";
      this.emoobject.disableIframe = false;
      this.emoobject.disableQR = false;
      this.emoobject.disableNameDisplay = false;
      this.emoobject.disablePoweredBy = false;
      this.emoobject.redirectUrl = "";
      this.emoobject.subscriptionId = "";

      this.emotion.sessionid = this.shared.sessionId();
      this.emotion.client_ver = this.client_ver;

      this.translate.get( 'GIVEUSASMILE_SUBTITLE' ).subscribe((data:any)=> {
        this.emoobject.subtitle = data;
      });

      this.translate.get( '5 Emotions' ).subscribe((data:any)=> {
        this.titleService.setTitle(data);
      });
  
      this.translate.get('THANKYOU').subscribe((data:any)=> {
        this.thanksmessage = data;
       });

      this.translate.get('Give us a smile').subscribe((data:any)=> {
        this.emoobject.subtitle = data;
      });

       this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnInit() {

    let pathname = window.location.pathname;
    if (pathname && pathname.length > 2) {
      let oids = pathname.split('/');
      if (Array.isArray(oids) && oids[1]) {
        this.emoobject.objectid = oids[1];
        this.emotion.objectid = this.emoobject.objectid;
      }

      if (Array.isArray(oids) && oids[2]) {

        if (this.emotion.params === undefined) {
          this.emotion.params = {};
        }

        this.emotion.params['m'] = oids[2];
        switch (oids[2]) {
          case 'qr':
            this.mode = 'qr';
            break;
          case 'kiosk':
            this.mode = 'kiosk';
            break;
          case 'mail':
            this.mode = 'mail';
            break;
          case 'share':
            this.mode = 'share';
            break;
          default:
            break;
        }
      }
    }

    this.emotion.theme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark': 'light';
    if (this.isIframe) {
      this.document.body.classList.add('emotions-iframe-body');
    }

    let emoob = this.store.getEmoObject(this.emoobject) as Observable<EmoObject[]>;
    emoob?.subscribe(async res => {

      let eo = (res && res[0]) ? res[0] : new EmoObject();
      await this.applyEmoObject(eo);
      this.showEmojis = true;
      this.updateDisplayMode();

    }, async error => {

      let eo: EmoObject | undefined;
      if (this.mode === 'kiosk') {
        this.store.getLocalEmoObject().subscribe(async res => {

          eo = (res && res[0]) ? res[0] : new EmoObject();
          await this.applyEmoObject(eo);

        }, async error => {
          await this.applyEmoObject(new EmoObject());
        });
      } else {
        eo = new EmoObject();
      }

      if (eo) {
        await this.applyEmoObject(eo);
      }
      this.showEmojis = true;
    })

    if (this.emoobject.objectid === 'emotions') {
      this.emoobject.subscriptionId = 'emotions';
      this.emoobject.redirectUrl = '';
      this.emoobject.disablePoweredBy = true;
    }

    let accept_terms = this.shared.getAcceptTerms();
    if (accept_terms && accept_terms.time && accept_terms.time < Date.now() - 3600000) {

      this.useGeo = true;
      this.sensorsDialogs();
    }

    fromEvent(window, 'load')
      .pipe(debounceTime(500))
      .subscribe((evt) => {
        this.updateDisplayMode(evt);
      });

    fromEvent(window, 'resize')
      .pipe(debounceTime(500))
      .subscribe((evt) => {
        this.updateDisplayMode(evt);
      });
  }

  private windowSize(): any {

    let ret:any = {};
    ret.width = window.innerWidth
      || window.document.documentElement.clientWidth
      || window.document.body.clientWidth
      || this.windowsize.width;

    ret.height = window.innerHeight
      || window.document.documentElement.clientHeight
      || window.document.body.clientHeight
      || this.windowsize.height;

    return ret;
  }

  private async applyEmoObject(emo: EmoObject) {

    if ((emo.objectid === this.emoobject.objectid) || (emo.customid === this.emoobject.objectid)) {

      this.emoobject.customid = emo.customid;
      this.emoobject.ownername = emo.ownername;
      this.emoobject.owneremail = emo.owneremail;
      this.emoobject.params = emo.params;
      this.emoobject.url = emo.url;
      this.emoobject.redirectUrl = emo.redirectUrl;
      this.emoobject.emojiStyle = emo.emojiStyle;
      this.emoobject.title = emo.title;
      if (emo.subtitle && emo.subtitle.length > 0) {
        this.emoobject.subtitle = emo.subtitle;
      }
      this.emoobject.disableQR = emo.disableQR;
      this.emoobject.disableNameDisplay = emo.disableNameDisplay;
      this.emoobject.disableIframe = emo.disableIframe;
      this.emoobject.disablePoweredBy = emo.disablePoweredBy;
      this.emoobject.subscriptionId = emo.subscriptionId;
      if (this.emoobject.ownername && this.emoobject.ownername.length > 0) {
        this.transparams = {ownername: this.emoobject.ownername};
      }

      if (this.emoobject.title && this.emoobject.title.length > 0) {
        this.emotitle = this.emoobject.title;
      }
      
    }

    await this.initEmotion();
  }

  private updateDisplayMode(e?: Event) {

    this.windowsize.width = (e?.currentTarget as Window)?.innerWidth;
    this.windowsize.height = (e?.currentTarget as Window)?.innerHeight;
    let size = this.windowSize();
    let showsubs = (this.mode === 'kiosk') && (size.width >= 300) && (size.height >= 300) && (!this._bottomsheetref);
    if (showsubs && this.emoobject.subscriptionId && this.emoobject.subscriptionId.length > 3 && this.emoobject.disableQR)
      showsubs = false;
    
    this.showQR = showsubs;
    this.showTitle = (this.emotitle.length > 0) && (size.width >= 300) && (size.height >= 300) && (!this._bottomsheetref);
    this.showHelp = ((size.width >= 300) && (size.height >= 300) && (!this._bottomsheetref) && (!this.emoobject.subscriptionId || !this.emoobject.disableNameDisplay));
  }

  private async initEmotion() {

    this.emotion.uuid = this.shared.createUniqueId();
    this.emotion.value = 0;
    this.emotion.sentiment = 0.0;
    this.emotion.comment = undefined;
    this.emotion.url = window.location.href;
    this.emotion.referrer = this.document.referrer;

    let size = this.windowSize();
    this.emotion.width = size.width;
    this.emotion.height = size.height;

    if (this.isIframe) {

      try {
        if ((!this.emotion.referrer || this.emotion.referrer.length <= 0) && (window.opener && window.opener.location)) {
            this.emotion.referrer = window.opener.location.href;
        }
        if ((!this.emotion.topurl || this.emotion.topurl.length <= 0) && (window.top && window.top.location)) {
          this.emotion.topurl = window.top.location.href; 
        }
        if ((!this.emotion.parenturl || this.emotion.parenturl.length <= 0) && (window.parent && window.parent.location)) {
          this.emotion.parenturl = window.parent.location.href; 
        }
      } catch (error) {
        this.shared.trackException(error as Error);
      }
    }

    switch (this.emoobject.emojiStyle) {
      case 'classic': {

          let p = "/assets/icons/emoji-";
          let ex = ".png";
          this.emoji1 = p + "classic-1" + ex;
          this.emoji2 = p + "classic-2" + ex;
          this.emoji3 = p + "classic-3" + ex;
          this.emoji4 = p + "classic-4" + ex;
          this.emoji5 = p + "classic-5" + ex;
        }
        break;
      case 'standard': {

          let p = "/assets/icons/emoji-";
          let ex = ".png";
          this.emoji1 = p + "standard-1" + ex;
          this.emoji2 = p + "standard-2" + ex;
          this.emoji3 = p + "standard-3" + ex;
          this.emoji4 = p + "standard-4" + ex;
          this.emoji5 = p + "standard-5" + ex;
        }
        break;
      case 'comic': {

          let p = "/assets/icons/emoji-";
          let ex = ".png";
          this.emoji1 = p + "comic-1" + ex;
          this.emoji2 = p + "comic-2" + ex;
          this.emoji3 = p + "comic-3" + ex;
          this.emoji4 = p + "comic-4" + ex;
          this.emoji5 = p + "comic-5" + ex;
        }
        break;
      case 'donut': {

          let p = "/assets/icons/emoji-";
          let ex = ".png";
          this.emoji1 = p + "donut-1" + ex;
          this.emoji2 = p + "donut-2" + ex;
          this.emoji3 = p + "donut-3" + ex;
          this.emoji4 = p + "donut-4" + ex;
          this.emoji5 = p + "donut-5" + ex;
        }
        break;
      default:
        break;
    }

    if (this.mode === 'kiosk') {

      let e                 = new EmoObject();
      e.id                  = this.emoobject.objectid;
      e.customid            = this.emoobject.customid;
      e.disableNameDisplay  = this.emoobject.disableNameDisplay;
      e.disablePoweredBy    = this.emoobject.disablePoweredBy;
      e.disableQR           = this.emoobject.disableQR;
      e.emojiStyle          = this.emoobject.emojiStyle;
      e.objectid            = this.emoobject.objectid;
      e.ownername           = this.emoobject.ownername;
      e.params              = this.emoobject.params;
      e.subscriptionId      = this.emoobject.subscriptionId;
      e.subscriptionValid   = this.emoobject.subscriptionValid;
      e.subtitle            = this.emoobject.subtitle;
      e.title               = this.emoobject.title;
      await this.store.addLocalEmoObject(e);

      if (!this.isIframe) {
        this.shared.logout(window.location.href);
      }

      this.qrcode = environment.SHORTURL + "/q/" + this.emoobject.objectid;
      this.useGeo = true;
      this.sensorsDialogs();

      // Disable back button navigation
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
      };
    }

    this.updateDisplayMode();

    if (this.useGeo) {
      this.sensorsDialogs();
    }
  }

  async onRate1() { await this.onRate(1, this.emoji1) }
  async onRate2() { await this.onRate(2, this.emoji2) }
  async onRate3() { await this.onRate(3, this.emoji3) }
  async onRate4() { await this.onRate(4, this.emoji4) }
  async onRate5() { await this.onRate(5, this.emoji5) }

  private async onRate(rate: number, icon: string) {

    this.emotion.time = Date.now();
    this.emotion.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let iconsplit = icon.split('/');
    this.emotion.icon = iconsplit[ iconsplit.length-1 ];
    if (this.useGeo) {
      this.emotion.position = this.shared.getPosition();
    }
    this.emotion.gender = this.shared.getGender();
    this.emotion.yearofbirth = this.shared.getYearOfBirth();

    switch (rate) {
      case 1:
        this.emotion.value = 1;
        this.emotion.sentiment = 0.0;
        break;
      case 2:
        this.emotion.value = 2;
        this.emotion.sentiment = 0.25;
        break;
      case 3:
        this.emotion.value = 3;
        this.emotion.sentiment = 0.5;
        break;
      case 4:
        this.emotion.value = 4;
        this.emotion.sentiment = 0.75;
        break;
      case 5:
        this.emotion.value = 5;
        this.emotion.sentiment = 1.0;
        break;
            
      default:
        break;
    }
    this.showEmojis = false;
    this.showTitle = false;

    let size = this.windowSize();
    let secs = 10;
    if (size.width >= 300 && size.height >= 300) {
      this.showBottomSheet();
    } else {

      this.showThanks = true;
      setTimeout( async () => {

        this.showThanks = false;
        if (this.emoobject.subscriptionId && 
          this.emoobject.subscriptionId.length > 3 &&
          this.emoobject.disablePoweredBy) {

            await this.finishRating();
        } else {
          this.startPowerTimer(5);
        }

      }, 4000);
    }

    this.updateDisplayMode();

    let e = {...this.emotion};
    await this.store.postEmotion(e); 
  }

  private showBottomSheet() {
    if (!this._bottomsheetref) {

      this.showThanks = true;
      this._bottomsheetref = this._bottomSheet.open(EmotionsBottomSheet).instance;

      let btref = this._bottomSheet._openedBottomSheetRef;
      if (btref) {

        btref.disableClose = true;
        btref.afterDismissed().subscribe( async _ => {
          await this.onCommentSubmit();
          this._bottomsheetref = undefined;
  
          if (this.emoobject.subscriptionId && 
            this.emoobject.subscriptionId.length > 3 &&
            this.emoobject.disablePoweredBy) {
  
              await this.finishRating();
    
          } else {
            this.startPowerTimer(5);
          }
  
        })
      }
    }
  }

  private hideBottomSheet() {
    if (this._bottomsheetref) {
      this._bottomSheet.dismiss();
    }
  }
  
  async onCommentSubmit() : Promise<void> {

    this.showThanks = false;

    let cmnt = undefined;
    if (this._bottomsheetref) {
      cmnt = this._bottomsheetref.commentSubmitted;
      cmnt = cmnt?.trim();
    }

    if (!cmnt || cmnt.length === 0) {
      return;
    }

    this.emotion.time = Date.now();
    let e = {...this.emotion};

    e.comment = cmnt;
    await this.store.postEmotion(e); 

    this.hideBottomSheet();
    this.sensorsDialogs();
  }

  onThanksClick() : void {

    if (this.canpopup) {
      window.open("/", "_blank");
    }
  }

  private async finishRating() {

    this.progress_value = 0;

    if (this.mode === 'kiosk') {

      this.hideBottomSheet();
      
      await this.initEmotion();

      this.showEmojis = true;
      this.showTitle = (this.emotitle.length > 0);
      this.showThanks = false;
      
      this.progress_value = 0;
    } else if (this.mode === 'popup') {

        window.close();
        if (!window.closed) {
          this.router.navigate(['/']);          
        }

    } else if (!this.isIframe) {

      if (
        this.emoobject.subscriptionId && 
        this.emoobject.subscriptionId.length > 3 && 
        this.emoobject.redirectUrl && 
        this.emoobject.redirectUrl.startsWith('https://')) {

          window.location.href = this.emoobject.redirectUrl;

      } else {

        this.router.navigate(['/']);
      }

    } else {

      if (
        !this.emoobject.subscriptionId || 
        this.emoobject.subscriptionId.length <= 3 || 
        !this.emoobject.disablePoweredBy) {

        this.translate.get('POWEREDBY5EMO').subscribe((data:any)=> {
          this.thanksmessage = data;
        });
        this.canpopup = true;
  
        this.smallthanks = true;
        this.showThanks = true;
      }

    }
  }

  sensorsDialogs() {
    setTimeout(async () => {
      this.emotion.position = this.shared.getPosition();
      await this.shared.subscribeToPush();
      this.store.postPushInfo( this.shared.getPushInfo() );
    }, 1000);
  }

  private startPowerTimer(duration: number) {

    this.showThanks = true;
    this.showQR = false;
    this.showTitle = false;
    this.translate.get('POWEREDBY5EMO').subscribe((data:any)=> {
      this.thanksmessage = data;
    });

    this.smallthanks = true;
    this.hideBottomSheet();

    this.poweredby_progress_timer = interval(10);
    this.progress_value = 0;
    this.poweredby_timer_subscriber = this.poweredby_progress_timer.subscribe(async sec => {

      this.progress_value = sec / duration;
      this.progress_sec = sec;

      if (this.progress_sec === duration*100) {

        this.stopPowerTimer();
        await this.finishRating();
      }

    });
  }  

  private stopPowerTimer() {

    this.showThanks = false;
    this.translate.get('THANKYOU').subscribe((data:any)=> {
      this.thanksmessage = data;
    });

    this.smallthanks = false;
    this.progress_value = 0;
    this.poweredby_timer_subscriber?.unsubscribe();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {

    if (this.isIframe) {
      try {
        if ((!this.emotion.topurl || this.emotion.topurl.length <= 0) && event.data && event.data.parent && event.data.parent.length > 3) {
          this.emotion.topurl = event.data.parent;
          this.emotion.parenturl = event.data.parent;
        }
      } catch (error) {
        this.shared.trackException(error as Error);
      }
    }
  }

}
