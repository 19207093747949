import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { EmoObject } from '../core/model';

@Injectable({ providedIn: 'root' })
export class EmoObjectService extends EntityCollectionServiceBase<EmoObject> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('EmoObject', serviceElementsFactory);
  }
}
