
export class PushInfo {

  id: string | undefined;
  
  endpoint: string | undefined;
  expirationTime: number | undefined;
  key_p256dh: Uint8Array | null | undefined;
  key_auth: Uint8Array | null | undefined;
  userid: string | undefined;

  constructor(other?: Partial<PushInfo>) {

    if (other) {

      this.id = other.id;
      this.endpoint = other.endpoint;
      this.expirationTime = other.expirationTime;
      this.key_p256dh = other.key_p256dh;
      this.key_auth = other.key_auth;
      this.userid = other.userid;
    }
  }
}
