import { Position } from "./emotion";

export class EmoObject {

  id: string | undefined;
  
  uuid: string | undefined;
  locale: string | undefined;
  ownerid: string | undefined;
  ownername: string | undefined;
  owneremail: string | undefined;
  objectid: string | undefined;
  customid: string | undefined;
  title: string | undefined;
  params: {[index: string] : string} | undefined;
  position: Position | undefined;
  url: string | undefined;
  redirectUrl: string | undefined;
  emojiStyle: string | undefined;
  subtitle: string | undefined;
  disableQR: boolean | undefined;
  disableNameDisplay: boolean | undefined;
  disableIframe: boolean | undefined;
  disablePoweredBy: boolean | undefined;
  subscriptionId: string | undefined;
  subscriptionValid: string | undefined;

  constructor(other?: Partial<EmoObject>) {

    if (other) {

      this.id = other.id;
      this.uuid = other.uuid;
      this.locale = other.locale;
      this.ownerid = other.ownerid;
      this.ownername = other.ownername;
      this.owneremail = other.owneremail;
      this.objectid = other.objectid;
      this.customid = other.customid;
      this.title = other.title;
      this.params = other.params;
      this.position = other.position;
      this.url = other.url;
      this.redirectUrl = other.redirectUrl;
      this.emojiStyle = other.emojiStyle;
      this.subtitle = other.subtitle;
      this.disableQR = other.disableQR;
      this.disableNameDisplay = other.disableNameDisplay;
      this.disableIframe = other.disableIframe;
      this.disablePoweredBy = other.disablePoweredBy;
      this.subscriptionId = other.subscriptionId;
      this.subscriptionValid = other.subscriptionValid;
    }
  }
}
