import { Component, OnInit } from '@angular/core';
import { AcceptTerms } from '../../core/model';
import { SharedService } from '../../shared/shared.service';
import { StoreService } from '../../store/store.service';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-dialog-terms',
  templateUrl: './dialog-terms.component.html',
  styleUrls: ['./dialog-terms.component.scss']
})
export class DialogTermsComponent implements OnInit {

  constructor(
    private shared: SharedService,
    private store: StoreService
  ) { }

  ngOnInit(): void {
  }

  onAcceptTerms(): void {

    this.shared.setAcceptTerms(undefined);

    let accepted = new AcceptTerms()
    accepted.accept = "yes"
    accepted.time = Date.now()
    accepted.client_ver = packageInfo.version;
    let res = this.store.postAcceptTerms(accepted)?.subscribe(data => {

      if (data) {

        this.shared.setAcceptTerms(data);
      }
    }, error => {

      this.shared.trackException(error);
    });

  }

}
