import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { WebpackTranslateLoader } from "./webpack-translate-loader";
import { ModuleWithProviders } from "@angular/core";
import { SharedService } from "./shared.service";
import {
  MsalGuard, 
  MsalModule,
  MsalInterceptor,
  MsalService,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "../app-config";
import { StripeProvider } from "./stripe.provider";


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader
            },
            isolate : false,
            defaultLanguage: 'de'
        }),
        HttpClientModule,
        MsalModule
    ],
    exports: [
        TranslateModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        StripeProvider
        ],
    bootstrap: [SharedService, MsalRedirectComponent]
})
  
export class SharedModule {

    static forRoot(): ModuleWithProviders<SharedModule> {

        return {
            ngModule: SharedModule,
            providers: [SharedService]
        }
    };

}
