import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { EmoSubscription } from '../core/model';

@Injectable({ providedIn: 'root' })
export class SubscriptionService extends EntityCollectionServiceBase<EmoSubscription> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('EmoSubscription', serviceElementsFactory);
  }
}
