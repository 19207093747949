import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../../shared/shared.service';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent implements OnInit {

  public message = "";
  public title = "";
  public icon = "";

  constructor(
    private dialogRef: MatDialogRef<DialogInfoComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

  }

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
    this.icon = this.data.icon;
  }

}
