import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './router';

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: false,
    enableTracing: false,
    anchorScrolling: 'enabled',
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'enabled'
   })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
