import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { SigninComponent } from './signin.component';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

const routes: Routes = [
  {
    path: '',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },
  {
    // Needed for hash routing
    path: 'error',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },
  {
    // Needed for hash routing
    path: 'state',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },
  {
    // Needed for hash routing
    path: 'code',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },

  // Hash routes used by MSAL.
  // These must appear here so that Angular knows about these "routes".
  // Sourced from here: https://git.io/Jelf3
  {
    // Needed for hash routing
    path: 'access_token',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },
  {
    // Needed for hash routing
    path: 'id_token',
    component: SigninComponent,
    data: { title: 'SIGNIN' }
  },

];

@NgModule({
  declarations: [
    SigninComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    ToolbarModule,
    SharedModule
  ]
})
export class SigninModule { }
