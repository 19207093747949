import { environment } from './../environments/environment';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

const root = environment.API || 'api';

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signup_signin",
        editProfile: "B2C_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://fiveemotions.b2clogin.com/fiveemotions.onmicrosoft.com/B2C_1_signup_signin",
        },
        editProfile: {
            authority: "https://fiveemotions.b2clogin.com/fiveemotions.onmicrosoft.com/b2c_1_edit_profile"
        }
    },
    authorityDomain: "fiveemotions.b2clogin.com"
};

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: {scopes: string[]; uri: string} = {
    scopes: ['https://fiveemotions.onmicrosoft.com/api/ScopeAll', 'openid'],
    uri: root
};

export function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {

    switch (level) {
        case LogLevel.Warning:
            console.warn('MSAL LOGGING: ' + level + ' ' + message);
            break;
        case LogLevel.Info:
            console.info('MSAL LOGGING: ' + level + ' ' + message);
            break;
        case LogLevel.Error:
            console.error('MSAL LOGGING: ' + level + ' ' + message);
            break;
        default:
            console.log('MSAL LOGGING: ' + level + ' ' + message);
            break;
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: 'df0d9fff-f208-4fd0-b5e9-b7c1ce73e58a',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: '/signin',
        postLogoutRedirectUri: '/signin',
        knownAuthorities: [b2cPolicies.authorityDomain]
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    });
}
  
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
  
    protectedResourceMap.set(`${root}/emotions/`, apiConfig.scopes);
    protectedResourceMap.set(`${root}/emoobjects/`, apiConfig.scopes);
    protectedResourceMap.set(`${root}/users/`, apiConfig.scopes);
    protectedResourceMap.set(`${root}/subscriptions/`, apiConfig.scopes);
    protectedResourceMap.set(`${root}/accesstoken/`, apiConfig.scopes);
  
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', apiConfig.scopes);
    
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
}
  
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [...apiConfig.scopes],
      },
    };
}
