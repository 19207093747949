import { Routes } from '@angular/router';
import { EmotionsComponent } from './emotions/emotions.component';
import { TermsComponent } from './about/terms/terms.component';
import { PrivacyComponent } from './about/privacy/privacy.component';
import { ImprintComponent } from './about/imprint/imprint.component';
import { AboutComponent } from './about/about/about.component';

export const routes: Routes = [

  {
    path: "",
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },

  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./stripe/stripeproduct/stripe-routing.module').then(m => m.StripeRoutingModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'doc',
    loadChildren: () => import('./doc/doc.module').then(m => m.DocModule)
  },
  {
    path: 'cam',
    loadChildren: () => import('./cam/cam.module').then(m => m.CamModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./print/print.module').then(m => m.PrintModule),
    data: { title: 'PRINT' }
  },
  {
    path: 'emotions',
    component: EmotionsComponent,
    data: { title: '5 Emotions' }
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: { title: 'TERMS' }
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: { title: 'PRIVACY' }
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    data: { title: 'IMPRINT' }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { title: 'ABOUT' }
  },
  {
    path: 'q/:value',
    redirectTo: ':value/qr'
  },
  {
    path: 'qr/:value',
    redirectTo: ':value/qr'
  },
  {
    path: 'kiosk/:value',
    redirectTo: ':value/kiosk'
  },
  {
    path: 'mail/:value',
    redirectTo: ':value/mail'
  },
  {
    path: 'sh/:value',
    redirectTo: ':value/share'
  },
  { path: '**', component: EmotionsComponent }
];
