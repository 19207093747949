import { environment } from '../../environments/environment';
import { RedirectToCheckoutClientOptions } from '@stripe/stripe-js';

/**
 * Checkout options for Stripe subscription client-side checkout
 */
 export const checkoutOptions: RedirectToCheckoutClientOptions = {
  lineItems: [
    {
      price: environment.STRIPE_API_PRICE,
      quantity: 1,
    },
  ],
  mode: "subscription",
  successUrl: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
  cancelUrl: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
  clientReferenceId: ""
  // customerEmail: this.email

};
