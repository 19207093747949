
export const environment = {
   production: true,
   API: "api",
   SHORTURL: "http://5emo.de",
   STRIPE_API_KEY: "pk_live_51IAysNEgrHJ8p8j31aUlvgVONFArvvorDtiJkf77IRXUtt1ggQMPvMNzmv8H3Sl62qn3lycXMols7HFza5iSWbGq00L9yyq71M",
   STRIPE_API_PRICE: "price_1If9PLEgrHJ8p8j30iAj58sa",
   APP_INSIGHTS_INSTRUMENTATION_KEY: "5d4adf4d-4ab3-4482-95a1-b9b445400d30",
   VAPID_KEY_PUBLIC: "BEc-w_-8x3paMWsaANOeHO48iKqSQcuyCMDxabzY00lbgKHzKSIwhShJYxmndxn5REIHYIlIVqgbr60SI_hZAkw",
   CRYPTO_SECRET: "D4BQTAG24fn7IuokmcKIPUkjnS3MVfbT"
};
