import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';

import { DefaultDataServiceConfig } from '@ngrx/data';
import { defaultDataServiceConfig } from './store/config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStoreModule } from './store/store.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToolbarModule } from './toolbar/toolbar.module';
import { SigninModule } from './signin/signin.module';
import { HomeModule } from './home/home.module';
import { EmotionsComponent } from './emotions/emotions.component';
import { ProfileModule } from './profile/profile.module';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import { FormsModule } from '@angular/forms';
import { AboutModule } from './about/about/about.module';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedService } from './shared/shared.service';
import { DialogTermsComponent } from './dialogs/dialog-terms/dialog-terms.component';
import { DialogProgressComponent } from './dialogs/dialog-progress/dialog-progress.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogInfoComponent } from './dialogs/dialog-info/dialog-info.component';
import { EmotionsBottomSheet } from './emotions/emotions.bottomsheet';

@NgModule({
  declarations: [
    AppComponent,
    EmotionsComponent,
    EmotionsBottomSheet,
    DialogTermsComponent,
    DialogProgressComponent,
    DialogInfoComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppStoreModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule.forRoot(),
    NgxStripeModule.forRoot(environment.STRIPE_API_KEY),
    MatCardModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDialogModule,
    HomeModule,
    AboutModule,
    SigninModule,
    ProfileModule,
    ToolbarModule,
    QRCodeModule
  ],
  exports: [

  ],
  providers: [

    SharedService,
    Title,
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

