<app-toolbar>

    <h1 class="home-title">{{'WELCOME_TITLE' | translate}}</h1>

    <h2>{{'WELCOME_INTRO' | translate}}</h2>

    <mat-list role="list">
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_LIMITS' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_LIMITS_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_BOUNDARIES' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_BOUNDARIES_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_DEVICE' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_DEVICE_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_INTERNET' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_INTERNET_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
          <mat-icon matListIcon>check</mat-icon>
          <h3 matLine>{{'NO_WAITING_TIME' | translate}}</h3>
          <p matLine>
            <span class="home-list-content">{{'NO_WAITING_TIME_EXP' | translate}}</span>
          </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_SIGN_IN' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_SIGN_IN_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_PROGRAMMING' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_PROGRAMMING_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_COSTS' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_COSTS_EXP' | translate}}</span>
            </p>
        </mat-list-item>
        <mat-list-item class="home-list-item" >
            <mat-icon matListIcon>check</mat-icon>
            <h3 matLine>{{'NO_ADS' | translate}}</h3>
            <p matLine>
              <span class="home-list-content">{{'NO_ADS_EXP' | translate}}</span>
            </p>
        </mat-list-item>
    </mat-list>

    <h2>{{'CLICK_AND_START' | translate}}</h2>

    <h1 class="home-title"><button class="button-center" [routerLink]="['/profile']" mat-stroked-button>{{'SIGNIN' | translate}}</button></h1>

    <div class="social-buttons">
      <a href='https://www.linkedin.com/company/5-emotions/' target='_blank' rel='noopener noreferrer'>
        <button class="button-linkedin" mat-stroked-button>in</button>
      </a>
    </div>

</app-toolbar>
