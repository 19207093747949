import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog-progress',
  templateUrl: './dialog-progress.component.html',
  styleUrls: ['./dialog-progress.component.scss']
})
export class DialogProgressComponent implements OnInit {

  constructor(  ) { }

  ngOnInit(): void {
  }

}
