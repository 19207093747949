import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from './shared/shared.service';
import { DialogTermsComponent } from './dialogs/dialog-terms/dialog-terms.component';
import { SwUpdate } from '@angular/service-worker';
import { StoreService } from './store/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = '5 Emotions';
  isIframe = false;
  private showDialog = true;
  private dialogref: MatDialogRef<DialogTermsComponent, any> | undefined;
  private eventsubscriber: Subscription | undefined;

  constructor(
    private router: Router, private route: ActivatedRoute,
    private shared: SharedService,
    private store: StoreService,
    private dialog: MatDialog,
    update: SwUpdate) 
  {

      if (update.available) {

        update.available.subscribe(_ => {

          // TODO: check internet bandwidth (navigator.connection.type ? "cellular", "wifi")
          let act = true;
          if (act) {

            update.activateUpdate()
            .then(() => {
              document.location.reload();
            })
            .catch(error => {
              this.shared.trackException(error);
            })
          }
        });
      }

      this.isIframe = window !== window.parent && !window.opener;

      let accept_terms = this.shared.getAcceptTerms();
      if (accept_terms === null) {

        this.eventsubscriber = this.router.events.subscribe(event => {

          if (event instanceof(NavigationEnd)) {
            this.changeDialog(event);
          }
        });
 
      } else {
        this.shared.startAppInsights(this.router);
      }

  }

  ngOnInit(): void {
  }

  openDialog() {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      this.dialogref = this.dialog.open(DialogTermsComponent, dialogConfig);
  }

  closeDialog() {

    if (this.dialogref !== undefined) {
      this.dialogref.close();
    }
  }
  
  changeDialog(event: NavigationEnd): void {

    let accept_terms = this.shared.getAcceptTerms();
    if (accept_terms !== null) {

      this.shared.startAppInsights(this.router);
      this.showDialog = false;
      this.closeDialog();
      this.eventsubscriber?.unsubscribe();
      return;
    }

    let paths = event.url.split('?');
    let path = paths[0];

    if ((path === '/privacy') || (path === '/terms') || (path === '/imprint') || (path === '/about') || (path === '/print')) {
        this.showDialog = false;
        this.closeDialog();
    } else {
      this.showDialog = true;
    }

    if (this.showDialog && !this.isIframe) {

      this.route.queryParamMap.subscribe(paramMap => {

        paramMap.keys.forEach((key) => { 
  
          let v = paramMap.get(key) as string;

          if ( (key === 'm') && (v === 'kiosk') ) {
  
            this.showDialog = false;
            this.closeDialog();
          }
        });

        if (this.showDialog) {
          this.openDialog();
        }
  
      });
  
    } else {

      this.showDialog = false;
      this.closeDialog();
    }

  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: Event): void {
      this.store.onFocus(event);
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: Event): void {
      this.store.onFocus(event);
  }

}
