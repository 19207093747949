<app-toolbar>

    <div (copy)="$event.preventDefault();" (paste)="$event.preventDefault();" (cut)="$event.preventDefault();" (contextmenu)="$event.preventDefault();">
    <mat-card class='card-section'>
        <mat-card-title>{{'MYOBJECT' | translate}}
            <img align="right" width="50px" height="50px" src="/assets/icons/5emotions-logo-trans-1000x1000.png" alt="MyObject" >
        </mat-card-title>
        <mat-card-subtitle>{{'OBJECTSUBTITLE' | translate}}</mat-card-subtitle>
        <mat-card-content id='myobject'>
    
            <mat-form-field appearance="fill">
                <mat-icon #ttp_objectid="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_OBJECTID' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_objectid.toggle()" >help</mat-icon>
                <mat-label>{{'OBJECTID' | translate}}</mat-label>
                <input matInput [(ngModel)]="newObjectId" readonly [disabled]="!objectValid" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-icon #ttp_customid="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_CUSTOMID' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_customid.toggle()" >help</mat-icon>
                <mat-label>{{'CUSTOMID' | translate}}</mat-label>
                <input #inputCustomId matInput [readonly]="!idEditable" [placeholder]="customid_paceholder" maxlength="100" type="text" [pattern]="customPattern" [(ngModel)]="newCustomid" (change)="onFormDataChange($event)" [disabled]="!objectValid" >
                <button mat-raised-button class="actionbutton" color="primary" (click)="onEditCustomId()" [disabled]="!objectValid" ><mat-icon>edit</mat-icon></button>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-icon #ttp_emotitle="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_EMOTITLE' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_emotitle.toggle()" >help</mat-icon>
                <mat-label>{{'EMOTITLE' | translate}}</mat-label>
                <input matInput maxlength="1000" type="text" [(ngModel)]="newEmotitle" (change)="onFormDataChange($event)" [disabled]="!objectValid" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'STYLE' | translate}}</mat-label>
                <mat-select [(ngModel)]="newEmojistyle" (selectionChange)="onFormDataChange()" [disabled]="!objectValid" >
                    <mat-option value="classic-fill">{{'classic-fill' | translate}}
                        <img src="/assets/icons/5emotions-button-200x50.png" >
                    </mat-option>
                    <mat-option value="classic">{{'classic' | translate}}
                        <img style="margin: 2px;" src="/assets/icons/5emotions-button-200x50-classic.png" >
                    </mat-option>
                    <mat-option value="standard">{{'standard' | translate}}
                        <img src="/assets/icons/5emotions-button-200x50-standard.png" >
                    </mat-option>
                    <mat-option value="comic">{{'comic' | translate}}
                        <img src="/assets/icons/5emotions-button-200x50-comic.png" >
                    </mat-option>
                    <mat-option value="donut">{{'donut' | translate}}
                        <img src="/assets/icons/5emotions-button-200x50-donut.png" >
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-icon #ttp_subtitle="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_SUBTITLE' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_subtitle.toggle()" >help</mat-icon>
                <mat-label>{{'SUBTITLE' | translate}}</mat-label>
                <input maxlength="50" matInput [(ngModel)]="newSubtitle" (change)="onFormDataChange($event)" [disabled]="!objectValid" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <button mat-raised-button class="actionbutton" color="primary" (click)="onShare()" [disabled]="!objectValid" title="{{'SHARE' | translate}}" ><mat-icon>share</mat-icon></button>
                <button mat-raised-button class="actionbutton" color="primary" (click)="printQR()" [disabled]="!objectValid" ><mat-icon>print</mat-icon>{{'PRINTQR' | translate}}</button>
                <input matInput readonly hidden >
            </mat-form-field>
    
        </mat-card-content>
    </mat-card>
        
    <mat-card class='card-section' >
    <mat-card-title (click)="checkout()">{{'PREMIUM' | translate:transparams}}

        <img align="right" width="50px" height="50px" src="/assets/icons/5emotions-logo-premium-1000x1000.png" alt="Premium" >
    </mat-card-title>
    <mat-card-subtitle (click)="checkout()">{{'PREMIUMSUBTITLE' | translate}}</mat-card-subtitle>
    <mat-card-content id='premium'>

        <mat-form-field appearance="fill">
            <mat-icon #ttp_subscrid="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_SUBSCRIPTIONID' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_subscrid.toggle()" >help</mat-icon>
            <mat-label>{{'SUBSCRIPTIONID' | translate}}</mat-label>
            <input matInput readonly value="{{subscriptionId}}" [disabled]="!hasPremium" (click)="checkout()" >
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-icon #ttp_validuntil="matTooltip" class="tooltip-icon" disabled="!hasPremium" matTooltip="{{'TOOLTIP_VALIDUNTIL' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_validuntil.toggle()" >help</mat-icon>
            <mat-label (click)="checkout()">{{'VALIDUNTIL' | translate}}</mat-label>
            <input matInput readonly value="{{subscriptionValid}}" [disabled]="!hasPremium" (click)="checkout()" >
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="fill">
            <mat-icon #ttp_disqr="matTooltip" class="tooltip-icon" color='{{hasPremium? "black":"gray"}}' matTooltip="{{'TOOLTIP_DISABLEQR' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_disqr.toggle()" >help</mat-icon>
            <mat-label (click)="checkout()">{{'DISABLEQR' | translate}}</mat-label>
            <mat-slide-toggle matInput [(ngModel)]="disableQR" [disabled]="!hasPremium" [(ngModel)]="newDisableQR" (change)="onFormDataChange()" >
            </mat-slide-toggle>
            <input matInput readonly hidden [disabled]="!hasPremium" >
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="fill">
            <mat-icon #ttp_disname="matTooltip" class="tooltip-icon" color='{{hasPremium? "black":"gray"}}' matTooltip="{{'TOOLTIP_DISABLENAMEDISPLAY' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_disname.toggle()" >help</mat-icon>
            <mat-label (click)="checkout()">{{'DISABLENAMEDISPLAY' | translate}}</mat-label>
            <mat-slide-toggle matInput [(ngModel)]="disableNameDisplay" [disabled]="!hasPremium" [(ngModel)]="newDisableNameDisplay" (change)="onFormDataChange()" >
            </mat-slide-toggle>
            <input matInput readonly hidden [disabled]="!hasPremium" >
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="fill">
            <mat-icon #ttp_dispower="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_DISABLEPOWER' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_dispower.toggle()" >help</mat-icon>
            <mat-label (click)="checkout()">{{'DISABLEPOWEREDBY' | translate}}</mat-label>
            <mat-slide-toggle matInput [(ngModel)]="disablePoweredBy" [disabled]="!hasPremium" [(ngModel)]="newDisablePoweredby" (change)="onFormDataChange()" >
            </mat-slide-toggle>
            <input matInput readonly hidden [disabled]="!hasPremium" >
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-icon #ttp_redirurl="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_REDIRECTURL' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_redirurl.toggle()" >help</mat-icon>
            <mat-label (click)="checkout()">{{'REDIRECTURL' | translate}}</mat-label>
            <input matInput type="url" placeholder="https://www.example.com" pattern="^(http|https)://\.(.*)" [disabled]="!hasPremium" [(ngModel)]="newRedirectUrl" (change)="onFormDataChange($event)" >
        </mat-form-field>
        <mat-form-field *ngIf="hasPremium" appearance="fill">
            <button mat-raised-button [disabled]="!hasPremium" class="actionbutton" color="warn" title="{{'CANCEL_SUBSCRIPTION' | translate}}" (click)="cancelSubscription()" ><mat-icon>delete_forever</mat-icon>{{'CANCEL_SUBSCRIPTION' | translate}}</button>
            <input matInput readonly hidden >
        </mat-form-field>

    </mat-card-content>
    </mat-card>
    
    <mat-card class='card-section' >
        <mat-card-title #PREMIUM id="PREMIUM" >{{'PROFILE' | translate}}
            <img align="right" width="50px" height="50px" src="/assets/icons/5emotions-bckgrnd-light-1000x1000.png" alt="MyObject" >
        </mat-card-title>
        <mat-card-subtitle>{{'PROFILESUBTITLE' | translate:transparams}}</mat-card-subtitle>
        <mat-card-content id='profile'>

            <mat-form-field appearance="fill">
                <mat-label>{{'TITLE' | translate}}</mat-label>
                <input maxlength="10" matInput readonly value="{{user.gender}}" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'USERNAME' | translate}}</mat-label>
                <input matInput readonly value="{{user.username}}" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-icon #ttp_displayname="matTooltip" class="tooltip-icon" matTooltip="{{'TOOLTIP_DISPLAYNAME' | translate}}" matTooltipShowDelay="0" matTooltipHideDelay="3000" matTooltipTouchGestures="on" (click)="ttp_displayname.toggle()" >help</mat-icon>
                <mat-label>{{'DISPLAYNAME' | translate}}</mat-label>
                <input matInput readonly value="{{user.name}}" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                <input matInput readonly value="{{user.given_name}}">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'LASTNAME' | translate}}</mat-label>
                <input matInput readonly value="{{user.family_name}}">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'EMAIL' | translate}}</mat-label>
                <input matInput type="email" readonly value="{{user.email}}">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'YEAROFBIRTH' | translate}}</mat-label>
                <input maxlength="4" type="number" matInput readonly value="{{user.yearofbirth}}" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <button mat-raised-button class="actionbutton" color="primary" title="{{'EDIT' | translate}}" (click)="editProfile()" ><mat-icon>edit</mat-icon></button>
                <button mat-raised-button class="actionbutton" color="warn"    title="{{'DELETEACCOUNT' | translate}}" (click)="deleteProfile()" ><mat-icon>delete_forever</mat-icon>{{'DELETEACCOUNT' | translate}}</button>
                <input matInput readonly hidden >
            </mat-form-field>
        
        </mat-card-content>
    </mat-card>
    </div>

</app-toolbar>
