import { DefaultDataServiceConfig } from '@ngrx/data';
import { environment } from './../../environments/environment';

const api = environment.API || 'api';

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: api, // default root path to the server's web api

  entityHttpResourceUrls: {

    PostAcceptTerms: {
      entityResourceUrl: `${api}/public/acceptterms/`,
      collectionResourceUrl: `${api}/public/acceptterms/`,
    },
    PostPushInfo: {
      entityResourceUrl: `${api}/public/pushinfo/`,
      collectionResourceUrl: `${api}/public/pushinfo/`,
    },
    PostEmotion: {
      entityResourceUrl: `${api}/public/emotions/`,
      collectionResourceUrl: `${api}/public/emotions/`,
    },
    GetEmoObject: {
      entityResourceUrl: `${api}/public/emoobject/`,
      collectionResourceUrl: `${api}/public/emoobject/`,
    },
    Emotion: {
      entityResourceUrl: `${api}/emotions/`,
      collectionResourceUrl: `${api}/emotions/`,
    },
    EmoObject: {
      entityResourceUrl: `${api}/emoobjects/`,
      collectionResourceUrl: `${api}/emoobjects/`,
    },
    User: {
      entityResourceUrl: `${api}/users/`,
      collectionResourceUrl: `${api}/users/`,
    },
    EmoSubscription: {
      entityResourceUrl: `${api}/subscriptions/`,
      collectionResourceUrl: `${api}/subscriptions/`,
    },
    AccessToken: {
      entityResourceUrl: `${api}/accesstoken/`,
      collectionResourceUrl: `${api}/accesstoken/`,
    },
  },
};
