import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  PostAcceptTerms: {},
  PostPushInfo: {},
  Emotion: {},
  PostEmotion: {},
  GetEmoObject: {},
  EmoObject: {},
  User: {},
  AccessToken: {},
  EmoSubscription: {}
};

export const entityConfig = {
  entityMetadata,
};
