import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Emotion } from '../core/model';

@Injectable({ providedIn: 'root' })
export class PostEmotionService extends EntityCollectionServiceBase<Emotion> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('PostEmotion', serviceElementsFactory);
  }
}
