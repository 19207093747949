import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogProgressComponent } from '../dialogs/dialog-progress/dialog-progress.component';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loggedIn = false;
  private dialogProgress: MatDialogRef<DialogProgressComponent, any> | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private shared: SharedService
  ) { 
    this.displayProgress(true);
  }

  ngOnInit() {
    this.loggedIn = this.shared.isLoggedin();

    this.route.fragment.subscribe(segments => {

      if (segments && segments.indexOf('state=') >= 0) {
        this.router.navigate(['/profile']).then(_ => {
          this.displayProgress(false);
        }).catch(error => {
          this.displayProgress(false);
        });
      }
      
      this.displayProgress(false);
    });
  }

  logout() {
    this.shared.logout();
  }

  private displayProgress(p?: Boolean) {

    if (p === true) {

      if (this.dialogProgress === undefined) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        this.dialogProgress = this.dialog.open(DialogProgressComponent, dialogConfig);
      }

    } else {

      if (this.dialogProgress !== undefined) {
        this.dialogProgress.close();
        this.dialogProgress = undefined;
      }
    }
  }

}
