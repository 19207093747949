<div class="emotionsdiv" (copy)="$event.preventDefault();" (paste)="$event.preventDefault();" (cut)="$event.preventDefault();" (contextmenu)="$event.preventDefault();">
<mat-icon *ngIf="showHelp" #ttp="matTooltip" class="emotions-tooltip-icon" matTooltip="{{'TOOLTIP_EMOTIONS' | translate:transparams}}" matTooltipShowDelay="0" matTooltipHideDelay="4000" matTooltipTouchGestures="on" (click)='ttp.toggle()' >help</mat-icon>
<mat-grid-list class="emotions-grid-list" cols="5" rowHeight="1:1" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:0, duration:10}}" [@leave-bounce]="{value:'', params:{delay:1000, duration:10}}">
    <mat-grid-tile>
        <button class="emotions-icon-button" mat-icon-button aria-label="emoji1" on-click="onRate1()" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:0}}" [@leave-bounce]="{value:'', params:{delay:400}}" >
            <img src="{{emoji1}}" width="100%" height="100%"  >
        </button>        
    </mat-grid-tile>
    <mat-grid-tile>
        <button class="emotions-icon-button" mat-icon-button aria-label="emoji2" on-click="onRate2()" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:100}}" [@leave-bounce]="{value:'', params:{delay:300}}" >
            <img src="{{emoji2}}" width="100%" height="100%"  >
        </button>        
    </mat-grid-tile>
    <mat-grid-tile>
        <button class="emotions-icon-button" mat-icon-button aria-label="emoji3" on-click="onRate3()" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:200}}" [@leave-bounce]="{value:'', params:{delay:200}}" >
            <img src="{{emoji3}}" width="100%" height="100%"  >
        </button>        
    </mat-grid-tile>
    <mat-grid-tile>
        <button class="emotions-icon-button" mat-icon-button aria-label="emoji4" on-click="onRate4()" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:300}}" [@leave-bounce]="{value:'', params:{delay:100}}" >
            <img src="{{emoji4}}" width="100%" height="100%"  >
        </button>        
    </mat-grid-tile>
    <mat-grid-tile>
        <button class="emotions-icon-button" mat-icon-button aria-label="emoji5" on-click="onRate5()" *ngIf="showEmojis" [@enter-bounce]="{value:'', params:{delay:400}}" [@leave-bounce]="{value:'', params:{delay:0}}" >
            <img src="{{emoji5}}" width="100%" height="100%"  >
        </button>        
    </mat-grid-tile>
</mat-grid-list>

<div class="emotions-thankscard" *ngIf="showThanks" [@enter-fade]="{value:'', params:{delay:1200, duration:100}}" [@leave-fade]="{value:'', params:{delay:10, duration:10}}" >
    <div class="emotions-thanksmessage" on-click="onThanksClick()" [style.font-size]="(smallthanks == true)? '7vw' : '14vw' "  >{{ thanksmessage }}</div>
</div>


<mat-card class="emotions-subcard" *ngIf="showQR || showTitle" >
    <mat-card-content class="emotions-commentcardcontent" >
        <div class="emotions-title" *ngIf="showTitle" >{{emotitle}}</div>
        <div class="emotions-fullQR" *ngIf="showQR" >
            <qrcode class="emotions-qrcodeimage" [qrdata]="qrcode" [width]="152" [margin]="1" [errorCorrectionLevel]="'M'"></qrcode>
            <div class="emotions-qrtext" >{{emoobject.subtitle}}</div>
        </div>
    </mat-card-content>
</mat-card>

</div>