import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { PushInfo } from '../core/model';

@Injectable({ providedIn: 'root' })
export class PostPushInfoService extends EntityCollectionServiceBase<PushInfo> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('PostPushInfo', serviceElementsFactory);
  }
}
