import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatBottomSheetConfig, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { ThemePalette } from "@angular/material/core";
import { ProgressBarMode } from "@angular/material/progress-bar";
import { interval, Observable, Subscription } from "rxjs";

/**
 * @title Emotions Bottom Sheet
 */
@Component({
  templateUrl: './emotions.bottomsheet.html',
  styleUrls: ['./emotions.bottomsheet.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmotionsBottomSheet implements OnInit {

  public commentevent: string | undefined;
  public commentSubmitted = "";

  progress_color: ThemePalette = 'primary';
  progress_mode: ProgressBarMode = 'determinate';
  progress_value = 0;

  progress_sec: number = 0;
  private progress_timer? : Observable<number>;
  private timer_subscriber? : Subscription;

  private invisible_progress_timer? : Observable<number>;
  private invisible_timer_subscriber? : Subscription;

  constructor(private _bottomSheetRef: MatBottomSheetRef<EmotionsBottomSheet>) {
  }

  ngOnInit() {

    this.commentevent = "";
    this._bottomSheetRef.afterOpened().subscribe(_ => {
      this.commentSubmitted = "";
      this.startTimer(10);
    })

    this._bottomSheetRef.afterDismissed().subscribe(_ => {
    })
  }

  onCommentInput($event: Event) : void {

    this.stopTimer();
    this.stopInvisibleTimer();
    this.startInvisibleTimer(30);

    let elm = $event.target as HTMLTextAreaElement;
    if (elm && elm.scrollHeight > elm.clientHeight) {
      elm.style.height = elm.scrollHeight + "px";
    }
  }

  onCommentSubmit() {

    this.commentSubmitted = (this.commentevent)? this.commentevent.trim(): "";
    this.stopTimer();
    this._bottomSheetRef.dismiss();
  }

  private startTimer(duration: number) {

    this.progress_timer = interval(10);
    this.progress_value = 0;
    this.timer_subscriber = this.progress_timer.subscribe((sec) => {

      this.progress_value = sec / duration;
      this.progress_sec = sec;

      if (this.progress_sec === duration*100) {
        this.stopTimer();
        this._bottomSheetRef.dismiss();
      }

    });
  }  

  private stopTimer() {

    this.progress_value = 0;
    this.timer_subscriber?.unsubscribe();
  }

  private startInvisibleTimer(duration: number) {

    this.invisible_progress_timer = interval(10);
    this.invisible_timer_subscriber = this.invisible_progress_timer?.subscribe((sec) => {

      if (sec === duration*100) {

        this.stopInvisibleTimer();
        this.startTimer(10);
      }

    });
  }  

  private stopInvisibleTimer() {
    this.invisible_timer_subscriber?.unsubscribe();
  }

}
