<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" >
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="toolbar-app-name">{{title}}</h1>

    <span class="example-spacer"></span>
    <button mat-icon-button title="{{'SIGNIN' | translate}}" class="example-icon" aria-label="Example icon-button with share icon" [routerLink]="['/signin']" [queryParamsHandling]="'preserve'" [preserveFragment]=true >
      <mat-icon>person</mat-icon>
    </button>

  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0"  color="primary">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" color="primary">
      <mat-nav-list>

        <mat-list-item title="{{'HOME' | translate}}" [routerLink]="'/'" [queryParamsHandling]="'preserve'" [preserveFragment]=true > <mat-icon>home</mat-icon> </mat-list-item>
        <mat-list-item title="{{'QUICKSTART' | translate}}" [routerLink]="'/doc/quickstart'" [queryParamsHandling]="'preserve'" color="primary" >{{'QUICKSTART' | translate}}</mat-list-item>
        <mat-list-item title="{{'DASHBOARD' | translate}}" *ngIf="loggedin" [routerLink]="'/dashboard'" [queryParamsHandling]="'preserve'" > <mat-icon>insert_chart</mat-icon> </mat-list-item>
        <mat-list-item title="{{'HOWITWORKS' | translate}}" [routerLink]="'/doc/howto'" [queryParamsHandling]="'preserve'" > {{'HOWITWORKS' | translate}} </mat-list-item>
        <mat-list-item title="{{'PRICING' | translate}}" [routerLink]="'/subscription'" [queryParamsHandling]="'preserve'" > {{'PRICING' | translate}} </mat-list-item>
        <mat-list-item title="{{'ABOUT' | translate}}" [routerLink]="'/about'" [queryParamsHandling]="'preserve'" > <mat-icon>info</mat-icon> </mat-list-item>

        <mat-list-item>
          <iframe width='150px' height='40px'
          src='/emotions' frameborder='0' allow='accelerometer; gyroscope' allowfullscreen allowTransparency="true">
          </iframe>
        </mat-list-item>

      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


