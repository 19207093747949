<app-toolbar>
    <mat-list role="list" (copy)="$event.preventDefault();" (paste)="$event.preventDefault();" (cut)="$event.preventDefault();">
        <mat-list-item role="listitem" [routerLink]="['/terms']" [queryParamsHandling]="'preserve'" >
            <button class="button-center" mat-stroked-button>{{'TERMS' | translate}}</button>
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="['/privacy']" [queryParamsHandling]="'preserve'" >
            <button class="button-center" mat-stroked-button>{{'PRIVACY' | translate}}</button>
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="['/imprint']" [queryParamsHandling]="'preserve'" >
            <button class="button-center" mat-stroked-button>{{'IMPRINT' | translate}}</button>
        </mat-list-item>
        <mat-list-item role="listitem" >
            <button class="button-center" mat-stroked-button>{{'VERSION' | translate}} : {{ver}}</button>
        </mat-list-item>
    </mat-list>

    <div class="social-buttons">
        <a href='https://www.linkedin.com/company/5-emotions/' target='_blank' rel='noopener noreferrer'>
          <button class="button-linkedin" mat-stroked-button>in</button>
        </a>
    </div>

</app-toolbar>

