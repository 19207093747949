
export class AcceptTerms {

  id: string | undefined;
  
  accept: string | undefined;
  client_ver: string | undefined;
  time: number | undefined;
  uuid: string | undefined;
  api_ver: string | undefined;
  _ts: number | undefined;
}
