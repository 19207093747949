import { Injectable } from "@angular/core";
import { StripeService } from "ngx-stripe";
import { SharedService } from "../shared/shared.service";
import { checkoutOptions } from "./stripe-config";

@Injectable({
    providedIn: 'root',
})
export class StripeProvider {

    constructor(
      private shared: SharedService,
      private stripeService: StripeService) {
    }

    canCheckout(): boolean {
      let acc = this.shared.getAccountId();
      if (acc && (acc.length > 0))
        return true;

      return false;
    }

    async checkout() {

      if (!this.canCheckout())
        return;

      let opt = checkoutOptions;
      opt.clientReferenceId = this.shared.getAccountId();

      // Call your backend to create the Checkout session.
      // When the customer clicks on the button, redirect them to Checkout.
      await this.stripeService.redirectToCheckout(opt).subscribe(result => {

        // console.log(result);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        let error = result.error;
        if (error) {

          let except = new Error();

          if (error.code)
          except.name = error.code;
          if (error.message)
          except.message = error.message;

          except.stack = JSON.stringify(error);

          this.shared.trackException(except);
        }
      });
    }

}
