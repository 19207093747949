export class Position {

  accuracy: number | undefined;
  altitude: number | null | undefined;
  heading: number | null | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  speed: number | null | undefined;
  time: number | undefined;
}

export class Emotion {

  client_ver: string | undefined;
  comment: string | undefined;
  gender: string | undefined;
  height: number | undefined;
  icon: string | undefined;
  id: string | undefined;
  objectid: string | undefined;
  params: {[index: string] : string} | undefined;
  parenturl: string | undefined;
  position: Position | undefined;
  referrer: string | undefined;
  sentiment: number | undefined;
  sessionid: string | undefined;
  theme: string | undefined;
  time: number | undefined;
  timeStr: string | undefined;
  timeStorage: number | undefined;
  timeZone: string | undefined;
  topurl: string | undefined;
  url: string | undefined;
  uuid: string | undefined;
  value: number | undefined;
  width: number | undefined;
  yearofbirth: number | undefined;

  constructor(other?: Partial<Emotion>) {

    if (other) {

      this.client_ver = other.client_ver;
      this.comment = other.comment;
      this.gender = other.gender;
      this.height = other.height;
      this.icon = other.icon;
      this.id = other.id;
      this.objectid = other.objectid;
      this.params = other.params;
      this.parenturl = other.parenturl;
      this.position = other.position;
      this.referrer = other.referrer;
      this.sentiment = other.sentiment;
      this.sessionid = other.sessionid;
      this.theme = other.theme;
      this.time = other.time;
      this.timeStr = other.timeStr;
      this.timeStorage = other.timeStorage;
      this.timeZone = other.timeZone;
      this.topurl = other.topurl;
      this.url = other.url;
      this.uuid = other.uuid;
      this.value = other.value;
      this.width = other.width;
      this.yearofbirth = other.yearofbirth;
    }
  }

}
