import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import {MediaMatcher} from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public title = "5 Emotions";
  public loggedin = false;

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher, 
    private route: ActivatedRoute,
    private translate: TranslateService,
    private shared: SharedService,
    private titleService: Title) {

      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addEventListener("change", this._mobileQueryListener);

      if (route.snapshot.data && route.snapshot.data['title']) {

        let subtitle = route.snapshot.data['title'];
        this.translate.get( subtitle ).subscribe((data:any)=> {
          subtitle = data;
          this.titleService.setTitle(this.title + " - " + subtitle);
          this.title = subtitle;
        });
      }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  @Output() public sidenavToggle = new EventEmitter();

  ngOnInit(): void {

    this.loggedin = this.shared.isLoggedin();
  }

}
