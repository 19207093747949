import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../environments/environment';
import {GeolocationService} from '@ng-web-apis/geolocation';

import * as METATAGS from '../../assets/i18n/metatags.json';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptTerms, Position, PushInfo } from '../core/model';
import { Subject } from 'rxjs';

import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { v4 as uuidv4 } from 'uuid';
import { MsalService, MsalGuardConfiguration, MsalBroadcastService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, AuthError, BrowserAuthError, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { apiConfig, b2cPolicies } from '../app-config';
import { HttpClient } from '@angular/common/http';

interface IdTokenClaims extends AuthenticationResult {
    idTokenClaims: {
        acr?: string
    }
}


@Injectable({
    providedIn: 'root',
})
export class SharedService {
  
    private metaTags = (METATAGS as any).default;
    private geoposition: Position | undefined;
    private appinsights: IApplicationInsights | undefined;
    private sessionid$ = uuidv4();
    private pushinfo: PushSubscription | undefined;
    private accessToken: AuthenticationResult | undefined;

    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
  
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private readonly geolocation$: GeolocationService,
        private metaService: Meta,
        readonly swPush: SwPush,
        private http: HttpClient)
    {
        this.initLanguage();

        this.metaTags.forEach((tag: any) => {

            let met = {} as MetaDefinition;
            if (tag.charset) met.charset = tag.charset;
            if (tag.content) met.content = tag.content;
            if (tag.httpEquiv) met.httpEquiv = tag.httpEquiv;
            if (tag.id) met.id = tag.id;
            if (tag.itemprop) met.itemprop = tag.itemprop;
            if (tag.name) met.name = tag.name;
            if (tag.property) met.property = tag.property;
            if (tag.scheme) met.scheme = tag.scheme;
            if (tag.url) met.url = tag.url;

            if (met.property === 'og:url')
                met.content = window.location.origin + window.location.pathname;
                
            if (met.content && tag.translate) {

                let cont = met.content as string;
                this.translate.get( cont ).subscribe((data:any)=> {
                    met.content = data;
                    this.metaService.updateTag(met);
                });
            } else {

                this.metaService.updateTag(met);
            }
        });

        this.initAuthentication();
    }

    initLanguage(): void {

        this.translate.addLangs(['en', 'de', 'fr']);
        const browserLang = this.translate.getBrowserLang();
        if (browserLang !== undefined) {
            this.translate.use(browserLang.match(/en|de|fr/) ? browserLang : 'en');
      
            this.route.queryParamMap.subscribe(async paramMap => {
              paramMap.keys.forEach((key) => { 
                if (key === 'l') {
                  let v = paramMap.get(key) as string;
                  this.translate.use(v.match(/en|de|fr/) ? v : 'de');
                }
              });
            });
        }
    }

    setCookie(name: string, value: string, days: number): void {

        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "")  + expires + "; path=/" + "; SameSite=None; Secure";
    }

    getCookie(name: string): string | null {

        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {

        var c = ca[i].trim();

        if (c.indexOf(nameEQ) == 0) 
            return c.substring(nameEQ.length,c.length);
        }

        return null;
    }

    eraseCookie(name: string) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT' + "; SameSite=None; Secure";
    }

    setAcceptTerms(a: AcceptTerms | undefined): void {

        let accepted = a
        if (!accepted) {

            accepted = new AcceptTerms()
            accepted.accept = "yes"
            accepted.time = Date.now()
        }
        
        if (!accepted.id || accepted.id as unknown as number === -1) {

            this.eraseCookie("accept-terms");
        } else {

            let cookie = JSON.stringify(accepted);
            this.setCookie("accept-terms", cookie, 365);
        }
    }

    getAcceptTerms(): any {

        let ret = null;
        let accepted = this.getCookie("accept-terms");
        if (accepted) {

            ret = JSON.parse(accepted);
        }

        return ret;
    }

    async subscribeToPush(): Promise<PushSubscription | undefined> {

        return new Promise( async (resolve, reject) => {

            if (this.pushinfo)
                resolve(this.pushinfo);

            if (!this.swPush.isEnabled)
                resolve(this.pushinfo);

            try {
                const sub = await this.swPush.requestSubscription({
                serverPublicKey: environment.VAPID_KEY_PUBLIC,
                })
                .then(sub => {
    
                    // console.log(sub);
                    this.pushinfo = sub;
                    resolve(this.pushinfo);
                })
                .catch(err => {
    
                    resolve(this.pushinfo);
                    // this.trackException(err);
                });
    
            // TODO: Send to server.
            } catch (err) {
                this.trackException(err as Error);
                resolve(this.pushinfo);
            }

            resolve(this.pushinfo);
        });
    }

    getPushInfo(): PushInfo {

        let ret = new PushInfo();
        if (this.pushinfo) {

            ret.endpoint = this.pushinfo.endpoint;

            let k = this.pushinfo.getKey("p256dh");
            if (k) {
                ret.key_p256dh = new Uint8Array(k);
            }
            let k2 = this.pushinfo.getKey("auth");
            if (k2) {
                ret.key_auth = new Uint8Array(k2);
            }
            ret.userid = this.accessToken?.uniqueId;

            // if (this.pushinfo.expirationTime)
            //     ret.expirationTime = this.pushinfo.expirationTime;
        }

        return ret;
    }

    getPosition(): Position | undefined {

        if (this.geolocation$) {

            this.geolocation$.subscribe(position => {
    
                // private geoposition?: any/* GeolocationPosition */;
                this.geoposition = new Position();
                this.geoposition.accuracy = position.coords.accuracy;

                if (position.coords.altitude)
                    this.geoposition.altitude = position.coords.altitude;
                if (position.coords.heading)
                    this.geoposition.heading = position.coords.heading;

                this.geoposition.latitude = position.coords.latitude;
                this.geoposition.longitude = position.coords.longitude;
                
                if (position.coords.speed)
                    this.geoposition.speed = position.coords.speed;

                this.geoposition.time = position.timestamp;
            }, error => {
                if (error.code == 1) {
                // console.log("user denied geoposition");
                } else {
                    this.trackException(error);
                }
                // console.log("geoposition error: ", error);
            });
        }

        return this.geoposition;
    }

    initAuthentication(): void {

        this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(res => {
            // console.log(res);
          this.setLoginDisplay();
        }, error => {
            this.trackException(error);
        });
    
        this.msalBroadcastService.msalSubject$
          .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            takeUntil(this._destroying$)
          )
          .subscribe((result: EventMessage) => {
          
            // console.log("MSAL Broadcast Service event: " , result);
            let auth = result.payload as AuthenticationResult;
            if (auth && (auth.accessToken.length > 0) && (auth.uniqueId.length > 0)) {
                // console.log("MSAL Broadcast Service: ", auth);
                this.accessToken = auth;
            }

            let payload: IdTokenClaims = <AuthenticationResult>result.payload;
    
            // We need to reject id tokens that were not issued with the default sign-in policy.
            // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
            // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    
            if (payload.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
              window.alert('Profile has been updated successfully. \nPlease sign-in again.');
              return this.logout();
            }
    
            return result;
          }, error => {
              this.trackException(error);
          });
    
          this.msalBroadcastService.msalSubject$
          .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
            takeUntil(this._destroying$)
          )
          .subscribe((result: EventMessage) => {

            // console.log(result);
            if (result.error instanceof BrowserAuthError) {
                // workaround: error possibly coming from iframe: force logout
                this.trackException(result.error);
            }
            if (result.error instanceof AuthError) {
                
                this.trackException(result.error);
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
              if (result.error.message.includes('AADB2C90118')) {
                
              }
            }
          }, error => {
              this.trackException(error);
          });
    }


    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    isLoggedin() {

        return this.authService.instance.getAllAccounts().length > 0;
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({...this.msalGuardConfig.authRequest, ...userFlowRequest} as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    // console.log(response);
                    this.authService.instance.setActiveAccount(response.account);
                }, error => {
                    this.trackException(error);
                });
            } else {
                this.authService.loginPopup(userFlowRequest)
                .subscribe((response: AuthenticationResult) => {
                    // console.log(response);
                    this.authService.instance.setActiveAccount(response.account);
                }, error => {
                    this.trackException(error);
                });
            }
        } else {
            if (this.msalGuardConfig.authRequest){
                this.authService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest} as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    logout(redirectUri?: string) {
        if (this.isLoggedin()) {
            let end = {
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                postLogoutRedirectUri : ""
            };
            if (redirectUri && redirectUri.length > 0) {
                end.postLogoutRedirectUri = redirectUri
            }

            this.authService.logoutRedirect(end);
        }
    }

    editProfile() {
        let editProfileFlowRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.editProfile.authority,
        };

        this.login(editProfileFlowRequest);
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    getProfile() {
        this.http.get(apiConfig.uri)
          .subscribe(profile => {
            // console.log(profile);
            // this.accessToken = profile as AuthResponse;
          }, error => {
              this.trackException(error);
          });
    }

    async getAccessToken(): Promise<AuthenticationResult | undefined>  {

        return new Promise( async (resolve, reject) => {
            if (this.accessToken) {
                resolve(this.accessToken);
            } else {
                let acc = this.authService.instance.getAllAccounts();
                if (acc && acc[0]) {
                    this.authService.instance.setActiveAccount(acc[0]);
                    let req = { scopes: apiConfig.scopes}
                    this.authService.acquireTokenSilent(req).subscribe(res => {
        
                        this.accessToken = res;
                        resolve(this.accessToken);
                    }, error => {
                        this.trackException(error);
                        resolve(undefined);
                    });
        
                } else {
                    resolve(undefined);
                }
            }
        })
    }

    getAccountId(): string | undefined {

        if (this.accessToken) {
            return this.accessToken.uniqueId;
        }

        let acc = this.authService.instance.getAllAccounts();
        if (acc && acc[0]) {
            return acc[0].localAccountId;
        }
        return undefined;
    }

    getGender(): string | undefined {
        let acc = this.authService.instance.getAllAccounts();
        if (acc && acc[0]) {
            let token = acc[0].idTokenClaims as any
            return token?.extension_TitleMrMrs
        }

        return undefined;
    }

    getYearOfBirth(): number | undefined {
        let acc = this.authService.instance.getAllAccounts();
        if (acc && acc[0]) {
            let token = acc[0].idTokenClaims as any
            return token?.extension_YearofBirth
        }

        return undefined;
    }

    startAppInsights(rt: Router) {

        if (!this.appinsights && window.navigator.onLine) {

            var angularPlugin = new AngularPlugin();
            const appInsights = new ApplicationInsights({ config: {
                instrumentationKey: environment.APP_INSIGHTS_INSTRUMENTATION_KEY,
                // extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: rt }
                }
            } });

            this.appinsights = appInsights.loadAppInsights();
        }
    }

    trackException(error: Error) {

        console.error(error);
        this.appinsights?.trackException({exception: error});
    }

    createUniqueId(): string {
        return uuidv4();
    }

    sessionId(): string {
        return this.sessionid$;
    }
}
