import { Injectable } from "@angular/core";
import { Key, NgxIndexedDBService } from "ngx-indexed-db";
import { Observable } from "rxjs";
import { EmoObject, Emotion } from "../core/model";
import { SharedService } from "../shared/shared.service";
// import CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class LocalStoreService {

    constructor(
        private dbService: NgxIndexedDBService,
        private shared: SharedService
        ) {
    }

    async addEmotion(e: Emotion): Promise<Emotion> {

        return new Promise((resolve, reject) => {

            let emo = new Emotion(e);
            emo.timeStorage = Date.now();
            this.dbService
            .add('emotions', emo)
            .subscribe(
                response => {
                    e = {...e, id: response.toString()};
                    resolve(e);
                }
                ,error => {
                    this.shared.trackException(error);
                    reject(error);
                }
            );        
        });
    }

    async getAllEmotions(): Promise<Emotion[]> {

        return new Promise((resolve, reject) => {

            this.dbService.getAll('emotions').subscribe((emotions) => {
                resolve( emotions as Emotion[] );
            }
            ,error => {
                resolve([]);
            });
        });
    }

    async addEmoObject(e: EmoObject): Promise<EmoObject> {

        return new Promise((resolve, reject) => {

            this.dbService.clear('emoobject').subscribe(_ => {

                this.dbService
                .add('emoobject', e)
                .subscribe(
                    response => {
                        e = {...e, id: response.toString()};
                        resolve(e);
                    }
                    ,error => {
                        this.shared.trackException(error);
                        reject(error);
                    }
                );        
    
            }, error => {
                console.log(error);
                resolve(new EmoObject());
            });

        });
    }

    public getEmoObject(): Observable<EmoObject[]> {

        return this.dbService.getAll('emoobject');
    }

    async deleteEmotion(e: Emotion): Promise<boolean> {

        return new Promise((resolve, reject) => {

            if (e.id) {
                this.dbService.delete('emotions', e.id).subscribe(res => {
                    resolve(true)
                }, error => {
                    reject(error);
                });
            }
        });
    }

    async clear(): Promise<boolean> {

        return new Promise((resolve, reject) => {

            this.dbService.clear('emotions').subscribe((successDeleted) => {
                resolve(successDeleted)
            }, error => {
                reject(error);
            });
        });
    }

    // public encrypt(s: any): string {
    //     return CryptoJS.AES.encrypt(JSON.stringify(s), environment.CRYPTO_SECRET).toString()
    // }

    // public decrypt(s: string): any {
    //     return CryptoJS.AES.decrypt(s, environment.CRYPTO_SECRET)
    // }
}
