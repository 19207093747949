import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import * as EN from '../../assets/i18n/en.json';
import * as DE from '../../assets/i18n/de.json';
import * as FR from '../../assets/i18n/fr.json';

const TRANSLATIONS = {
    en: EN,
    de: DE,
    fr: FR
};

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {

        let ret = TRANSLATIONS[lang as ( "en" | "de" | "fr" )] as any;
        return of(ret.default);
  }
}

