import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { SharedService } from '../shared/shared.service';
import { EmoObject, User } from '../core/model';
import { StoreService } from '../store/store.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DialogProgressComponent } from '../dialogs/dialog-progress/dialog-progress.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { DialogInfoComponent } from '../dialogs/dialog-info/dialog-info.component';
import { StripeProvider } from '../shared/stripe.provider';
import { AuthenticationResult } from '@azure/msal-common';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  @ViewChild('inputCustomId') inputCustomId: ElementRef | undefined;

  accessToken: AuthenticationResult | undefined;
  user = new User();
  loggedIn = false;
  hasPremium = false;
  transparams = {};
  emoobject: EmoObject = new EmoObject();
  objectValid = false;
  idEditable = false;
  customPattern = "[-_a-zA-Z0-9]+";

  disableQR = false;
  disableNameDisplay = false;
  disablePoweredBy = false;
  subscriptionId = "";
  subscriptionValid = "invalid";
  customid_paceholder = "";

  // private progress_sec: number = 0;
  // private progress_timer? : Observable<number>;
  // private timer_subscriber? : Subscription;

  newObjectId: string | undefined;
  newCustomid: string | undefined;
  newEmotitle: string | undefined;
  newEmojistyle = "";
  newSubtitle = "";
  newDisableQR = false;
  newDisableNameDisplay = false;
  newDisablePoweredby = false;
  newRedirectUrl = "";

  private dialogProgress: MatDialogRef<DialogProgressComponent, any> | undefined;
  private dialogInfo: MatDialogRef<DialogInfoComponent, any> | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private shared: SharedService,
    private store: StoreService,
    private dateAdapter: DateAdapter<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private stripeProvider: StripeProvider) {

      this.displayProgress(true);

      this.translate.addLangs(['en', 'de', 'fr']);
      const browserLang = this.translate.getBrowserLang();
      if (browserLang !== undefined) {
        this.translate.use(browserLang.match(/en|de|fr/) ? browserLang : 'en');
  
        this.route.queryParamMap.subscribe(async paramMap => {
          paramMap.keys.forEach((key) => { 
            if (key === 'l') {
              let v = paramMap.get(key) as string;
              this.translate.use(v.match(/en|de|fr/) ? v : 'de');
            }
          });
        });
      }
        
      this.dateAdapter.setLocale( translate.getBrowserCultureLang() );
      this.transparams = {value: '?'};

      this.emoobject.objectid = "";
      this.emoobject.emojiStyle = "classic-fill";
      this.emoobject.customid = "";
      this.emoobject.title = "";
      this.emoobject.redirectUrl = "";
      this.emoobject.disableIframe = false;
      this.emoobject.disableQR = false;
      this.emoobject.disableNameDisplay = false;
      this.emoobject.disablePoweredBy = false;
      this.emoobject.subtitle = "";
      this.emoobject.subscriptionId = "";
      this.emoobject.subscriptionValid = "";
      this.hasPremium = ((this.emoobject.subscriptionId !== undefined) && (this.emoobject.subscriptionId.length > 3));
      this.emoobject.position = this.shared.getPosition();

      this.translate.get( "GIVEUSASMILE_SUBTITLE" ).subscribe((data:any)=> {
        this.emoobject.subtitle = data;
      });

      this.translate.get( "CUSTOMID_PLACEHOLDER" ).subscribe((data:any)=> {
        this.customid_paceholder = data;
      });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(async paramMap => {
      await this.initProfile(paramMap);
    });
  }

  private displayProgress(p?: Boolean) {

    if (p === true) {

      if (this.dialogProgress === undefined) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        this.dialogProgress = this.dialog.open(DialogProgressComponent, dialogConfig);
      }

    } else {

      if (this.dialogProgress !== undefined) {
        this.dialogProgress.close();
        this.dialogProgress = undefined;
      }
    }
  }

  private displaySnackbar(message: string) {

    let conf = new MatSnackBarConfig();
    conf.duration = 3000;
    this.snackBar.open(message, undefined, conf);
  }

  private async initProfile(params?: ParamMap) {

    let at = await this.shared.getAccessToken();
    if (!at || !at.uniqueId) {
      this.displayProgress(false);
      this.shared.logout();
      this.router.navigate(['/signin']);
      return;
    }

    this.accessToken = at;
    this.store.postAccessToken(at);
    this.loggedIn = this.shared.isLoggedin();
    this.sensorsDialogs();

    this.user.id = at.uniqueId;
    this.user.userid = at.uniqueId;

    this.user.uniqueId = this.accessToken.uniqueId;
    let idTokenClaims = (this.accessToken.idTokenClaims as any);
    let idTokenClaims2 = (this.accessToken.account?.idTokenClaims as any);
    this.user.auth_time = idTokenClaims?.auth_time || idTokenClaims2?.auth_time;
    this.user.exp = idTokenClaims?.exp || idTokenClaims2?.exp;
    this.user.family_name = idTokenClaims?.family_name || idTokenClaims2?.family_name;
    this.user.gender = idTokenClaims?.extension_TitleMrMrs || idTokenClaims2?.extension_TitleMrMrs;
    this.user.given_name = idTokenClaims?.given_name || idTokenClaims2?.given_name;
    this.user.idp = idTokenClaims?.idp || idTokenClaims2?.idp;
    this.user.idp_access_token = idTokenClaims?.idp_access_token || idTokenClaims2?.idp_access_token;
    this.user.name = idTokenClaims?.name || idTokenClaims2?.name;
    this.user.username = this.accessToken.account?.username;
    this.user.yearofbirth = idTokenClaims?.extension_YearofBirth || idTokenClaims2?.extension_YearofBirth;
    if (idTokenClaims?.emails && idTokenClaims?.emails[0])
      this.user.email = idTokenClaims?.emails[0];
    else if (idTokenClaims2?.emails && idTokenClaims2?.emails[0])
      this.user.email = idTokenClaims2?.emails[0];

    this.user.position = this.shared.getPosition();
    if (idTokenClaims.emails &&  idTokenClaims.emails[0]) {
      this.user.email = idTokenClaims.emails[0];
    }

    this.transparams = {value: this.user.idp};

    let emoob = this.store.getEmoObject(this.user) as Observable<EmoObject[]>;
    emoob?.subscribe(res => {

      let emo = res[0] as EmoObject;
      if (emo && (emo.ownerid === this.user.id)) {

        this.emoobject = new EmoObject(emo);
        if (this.emoobject.id && this.emoobject.objectid) {
          this.objectValid = true;
        }

        this.newObjectId = this.emoobject.objectid!;
        if (this.emoobject.customid !== this.emoobject.objectid) {
          this.newCustomid = this.emoobject.customid!;
        }
        this.newEmotitle = this.emoobject.title!;
        this.newRedirectUrl = this.emoobject.redirectUrl!;
        this.newEmojistyle = this.emoobject.emojiStyle!;
        this.newSubtitle = this.emoobject.subtitle!;
        this.disableQR = this.emoobject.disableQR!;
        this.disableNameDisplay = this.emoobject.disableNameDisplay!;
        this.disablePoweredBy = this.emoobject.disablePoweredBy!;
        this.subscriptionId = this.emoobject.subscriptionId!;
        this.subscriptionValid = this.emoobject.subscriptionValid!;

        if (this.emoobject.subscriptionId && this.emoobject.subscriptionValid) {
          let now = Date.now();
          let valid = parseFloat(this.emoobject.subscriptionValid)*1000;
          let vdisplay = new Date(valid);
          this.hasPremium = (valid > now);
          this.subscriptionValid = vdisplay.toLocaleDateString();
        }
      }

      this.displayProgress(false);

    }, error => {

      this.displayProgress(false);
      this.shared.trackException(error);
    })
  }

  ngOnDestroy(): void {
    this.shared.ngOnDestroy();
  }

  printQR(): void {

    let oid = this.emoobject.objectid;
    if (this.emoobject.customid && this.emoobject.customid.length > 3) {
      oid = this.emoobject.customid;
    }
    let url = `/print?objectid=${oid}`;
    if (this.emoobject.subtitle)
      url += '&subtitle=' + encodeURI( this.emoobject.subtitle );

    window.open(url, '_5emopopup', 'width=800,height=600,alwaysRaised=yes');
  }

  printCustomQR(): void {

    let url = '/print?objectid=' + this.emoobject.customid;
    if (this.emoobject.subtitle)
      url += '&subtitle=' + encodeURI( this.emoobject.subtitle );

    window.open(url, '_5emopopup', 'width=800,height=600,alwaysRaised=yes');
  }

  onEditCustomId() {
    this.idEditable = !this.idEditable;
    if (this.idEditable) {
      setTimeout(() => {
        this.inputCustomId?.nativeElement.focus();
        this.inputCustomId?.nativeElement.select();
      }
    )}
  }

  onShare(): void {

    if (navigator.share) {

      let shorturl = environment.SHORTURL;

      let oid = this.emoobject.objectid;
      if (this.emoobject.customid && this.emoobject.customid.length > 3) {
        oid = this.emoobject.customid;
      }
      let url = `${shorturl}/${oid}?m=sh`;

      navigator.share({url: url}) 
    }
  }
  
  editProfile() {
    this.shared.editProfile();
  }

  deleteProfile() {

    let title = "";
    let message = "";
    this.translate.get( "DELETEACCOUNT" ).subscribe((str:any)=> {
      title = str;
    });

    this.translate.get( "TOOLTIP_DELETE_ACCOUNT" ).subscribe((str:any)=> {
      message = str;
    });

    if (this.dialogInfo === undefined) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.data = {message: message, title: title, icon: "warning"};
      this.dialogInfo = this.dialog.open(DialogInfoComponent, dialogConfig);

      this.dialogInfo.afterClosed().subscribe(res => {

        if (this.dialogInfo !== undefined) {
          this.dialogInfo.close();
          this.dialogInfo = undefined;
        }

        if (res === true) {

          let p = this.shared.getPushInfo();
          if (p.endpoint) {
            this.store.deletePushInfo(p)?.subscribe(_ => {

            });
          }

          this.shared.getAccessToken().then(at => {
            if (at) {
              this.store.deleteAccessToken(at)?.subscribe(res => {
                // console.log("deleteAccount success", res);
                this.shared.logout();
              })
            }
          });
        }
      });
    }
  }

  cancelSubscription() {

    let subs = this.emoobject.subscriptionId;
    if (!subs)
      return;

    let title = "";
    let message = "";
    this.translate.get( "CANCEL_SUBSCRIPTION" ).subscribe((str:any)=> {
      title = str;
    });

    let params = {expirydate: this.subscriptionValid};
    this.translate.get( "TOOLTIP_CANCEL_SUBSCRIPTION", params ).subscribe((str:any)=> {
      message = str;
    });

    if (this.dialogInfo === undefined) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.data = {message: message, title: title, icon: "warning"};
      this.dialogInfo = this.dialog.open(DialogInfoComponent, dialogConfig);

      this.dialogInfo.afterClosed().subscribe(res => {

        if (this.dialogInfo !== undefined) {
          this.dialogInfo.close();
          this.dialogInfo = undefined;
        }

        if (res === true && subs) {

          this.store.cancelSubscription(subs).subscribe(sub => {
          }, error => {
            this.displaySnackbar(error.message);
          });
        }
      });
    }
  }

  onFormDataChange(e?: Event) {
  
    this.idEditable = false;
    this.emoobject.customid = this.newCustomid || this.emoobject.objectid;
    this.emoobject.title = this.newEmotitle;
    this.emoobject.emojiStyle = this.newEmojistyle;
    this.emoobject.subtitle = this.newSubtitle;
    this.emoobject.disableQR = this.newDisableQR;
    this.emoobject.disableNameDisplay = this.newDisableNameDisplay;
    this.emoobject.disablePoweredBy = this.newDisablePoweredby;
    this.emoobject.redirectUrl = this.newRedirectUrl;
    this.emoobject.position = this.shared.getPosition();
    if (this.user && this.user.name) {
      this.emoobject.ownername = this.user.name;
    }
    if (this.user && this.user.email) {
      this.emoobject.owneremail = this.user.email;
    }

    let eo = new EmoObject(this.emoobject);
    this.store.postEmoObject( eo )?.subscribe(_ => {

    }, error => {
      this.displaySnackbar(error.message);
      this.shared.trackException(error);
    });
  }

  sensorsDialogs() {
    setTimeout(async () => {
      this.shared.getPosition();
      await this.shared.subscribeToPush();
      this.store.postPushInfo( this.shared.getPushInfo() );
    }, 1000);
  }

  async checkout() {
    if (this.hasPremium)
      return;

    this.displayProgress(true);
    await this.stripeProvider.checkout();
    this.displayProgress(false);
  }

}
