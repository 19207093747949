<div>
    <mat-progress-bar  *ngIf="progress_value > 0"
        [color]="progress_color"
        [mode]="progress_mode"
        [value]="progress_value">
    </mat-progress-bar>

    <div class="emotions-commentcardcontent" >

    <mat-form-field class="emotions-commenttext" >
        <mat-label>{{ 'COMMENT' | translate }}</mat-label>
        <textarea ngDefaultControl class="commenttextarea" matInput type="text" maxlength="10000" spellcheck="true" (focus)="onCommentInput($event)" (input)="onCommentInput($event)" [(ngModel)]="commentevent" ></textarea>
    </mat-form-field>

    <button mat-fab class="commentbutton"  color="primary" aria-label="submitcomment" on-click="onCommentSubmit()" >
        <mat-icon>send</mat-icon>
    </button>

    </div>

</div>
