import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AcceptTerms, EmoObject, Emotion, PushInfo, EmoSubscription, User } from "../core/model";
import { v4 as uuidv4 } from 'uuid';
import { SharedService } from "../shared/shared.service";
import { EmoObjectService } from "./emoobject.service";
import { EmotionService } from "./emotion.service";
import { SubscriptionService } from "./subscription.service";
import { UserService } from "./user.service";
import { AccesstokenService } from "./accesstoken.service";
import { PostEmotionService } from "./postemotion.service";
import { PostAcceptTermsService } from "./postacceptterms.service";
import { PostPushInfoService } from "./postpushinfo.service";
import { LocalStoreService } from "./localstore.service";
import { AuthenticationResult } from "@azure/msal-common";
import { GetEmoObjectService } from "./getemoobject.service";

@Injectable({
    providedIn: 'root',
})
export class StoreService {

    private postingLocals = false;
  
      constructor(
        private emotionService: EmotionService,
        private emoObjectService: EmoObjectService,
        private userService: UserService,
        private accesstokenService: AccesstokenService,
        private subscriptionService: SubscriptionService,
        private postEmotionService: PostEmotionService,
        private postAcceptTermsService: PostAcceptTermsService,
        private postPushInfoService: PostPushInfoService,
        private getEmoObjectService: GetEmoObjectService,
        private localStoreService: LocalStoreService,
        private shared: SharedService
    ) {
        setTimeout( async () => {
            await this.postLocalEmotions();
        }, 1000);
    }

    postAcceptTerms(a: AcceptTerms): Observable<AcceptTerms> | undefined {

        a.id = uuidv4();
        a.uuid = a.id;
        return this.postAcceptTermsService.add(a);
    }

    postPushInfo(p: PushInfo): Observable<PushInfo> | undefined {

        let ret;
        if (!p.endpoint)
            return ret;
            
        // console.log("postPushInfo: ", p);
        return this.postPushInfoService.add(p);
    }

    deletePushInfo(p: PushInfo): Observable<string | number> | undefined {

        let ret;
        if (!p.endpoint)
            return ret;
            
        return this.postPushInfoService.delete(p);
    }

    postUser(u: User): Observable<User> | undefined {

        return this.userService.add(u);
    }

    postAccessToken(at: AuthenticationResult): Observable<AuthenticationResult> | undefined {

        return this.accesstokenService.add(at);
    }

    deleteAccessToken(at: AuthenticationResult): Observable<string | number> | undefined {

        let id = at.uniqueId || at.account?.localAccountId;
        if (id && id.length > 3) {
            return this.accesstokenService.delete(id);
        }

        return undefined;
    }

    postEmoObject(e: EmoObject): Observable<EmoObject> | undefined {

        return this.emoObjectService.add(e);
    }

    async postEmotion(e: Emotion): Promise<Emotion> {

        return new Promise((resolve, reject) => {

            try {

                let emo = new Emotion(e);
                emo.id = uuidv4();
                if (window.navigator.onLine) {
                    let ret = this.postEmotionService.add(emo).subscribe(res => {
                        resolve(res);
                    }, error => {

                        this.localStoreService.addEmotion(emo).then(res => {
                            let ret = res;
                            resolve(ret);
                        }).catch(error => {
                            this.shared.trackException(error);
                            reject(error);
                        });
                    });
                }
                else {

                    this.localStoreService.addEmotion(emo).then(res => {
                        let ret = res;
                        resolve(ret);
                    }).catch(error => {
                        this.shared.trackException(error);
                        reject(error);
                    });
                }
                
            } catch (error) {
                this.shared.trackException(error as Error);
                reject(error);
            }

        });
    }

    private async postLocalEmotions(): Promise<boolean> {

        if (!window.navigator.onLine || this.postingLocals)
            return false;

        this.postingLocals = true;
        return new Promise((resolve, reject) => {

            try {

                this.localStoreService.getAllEmotions().then(async emos => {

                    emos.forEach(emo => {
    
                        this.postEmotionService.add(emo).subscribe(async res => {
    
                            await this.localStoreService.deleteEmotion(emo);
                        }, error => {
                            this.shared.trackException(error);
                            this.postingLocals = false;
                            reject(error);
                        });
                    });
    
                    this.postingLocals = false;
                    resolve(true);

                }).catch(error => {

                    this.shared.trackException(error);
                    this.postingLocals = false;
                    reject(error);
                });
                
            } catch (error) {

                this.shared.trackException(error as Error);
                this.postingLocals = false;
                reject(error);
            }
        });
    }

    async getAllEmotions(u: User): Promise<Emotion[] | undefined> {

        let uid = u.id;
        if (uid) {
            let ps = (u.page_size)? u.page_size.toString() : ""
            let pi = (u.page_index)? u.page_index.toString() : ""
            let oa = (u.order_active)? u.order_active : "time"
            let od = (u.order_direction)? u.order_direction : "desc"

            let query = {
                "ownerid": uid, 
                "page_size": ps, 
                "page_index": pi,
                "order_active": oa,
                "order_direction": od
            };
            return this.emotionService.getWithQuery(query).toPromise();
        }

        return undefined;
    }

    addUser(u: User): Observable<User> | undefined {

        let usr = new User(u);
        usr.id = uuidv4();
        let ret;
        try {
            ret = this.userService.add(usr);
        } catch (error) {
            this.shared.trackException(error as Error);
        }
        return ret;
    }

    getUser(u: User): Observable<User> {
        return this.userService.getByKey(JSON.stringify(u));
    }

    addEmoObject(e: EmoObject): Observable<EmoObject> | undefined {

        let eo = new EmoObject(e);
        eo.id = uuidv4();
        let ret;
        try {
            ret = this.emoObjectService.add(eo);
        } catch (error) {
            this.shared.trackException(error as Error);
        }
        return ret;
    }

    getEmoObject(u?: User | EmoObject): Observable<EmoObject[]> | EmoObject | undefined {

        if (!u) return undefined;

        let e = u as EmoObject;
        let usr = u as User;
        if (e && e.objectid && e.objectid.length > 3) {

            let query = {"objectid": e.objectid};
            return this.getEmoObjectService.getWithQuery(query);
        }
        else if (usr && usr.id) {

            let name = (usr.name) ? usr.name : ""
            let email = (usr.email) ? usr.email : ""
            let query = {"ownerid": usr.id, "ownername": name, "owneremail": email, "position": JSON.stringify( usr.position )};
            return this.emoObjectService.getWithQuery(query);
        }

        return undefined;
    }

    public getEmoObjectAsync(): Observable<EmoObject[]> {

        let id = this.shared.getAccountId();
        if (id) {
            let query = {"ownerid": id};
            return this.emoObjectService.getWithQuery(query); 
        } else {
            return this.getLocalEmoObject();
        }
    }

    async addLocalEmoObject(e: EmoObject): Promise<EmoObject> {

        return await this.localStoreService.addEmoObject(e);
    }

    public getLocalEmoObject(): Observable<EmoObject[]> {

        return this.localStoreService.getEmoObject();
    }

    addSubscription(s: EmoSubscription): Observable<EmoSubscription> | undefined {

        s.id = uuidv4();
        let ret;
        try {
            ret = this.subscriptionService.add(s);
        } catch (error) {
            this.shared.trackException(error as Error);
        }
        return ret;
    }

    getSubscription(u: User): Observable<EmoSubscription> {
        return this.subscriptionService.getByKey(JSON.stringify(u));
    }

    cancelSubscription(id:string): Observable<string | number> {
        return this.subscriptionService.delete(id);
    }

    onFocus(event: Event) {
        setTimeout( async () => {
            await this.postLocalEmotions();
        }, 1000);
    }

}
